<template>
  <div>
    <div class="row">
      <div class="col">
        <be-form-group
          :label="$t('components.meetings.invitations.invitation_message')"
          label-for="invitation-message"
        >
          <be-form-textarea
            id="invitation-message"
            :model-value="message"
            rows="4"
            max-rows="20"
            @update="(value) => $emit('update:message', value)"
          />
        </be-form-group>
      </div>
    </div>

    <div v-if="!preinvitation" class="row mb-3">
      <div class="col">
        <be-form-checkbox
          class="mt-2"
          :checked="includeAgenda"
          :disabled="agendaAboveAttachmentLimit"
          :description="
            $t('models.invitation_batch.hints.include_agenda_w_limit', {
              limit: meeting.attachment_limit.human,
            })
          "
          @input="(value) => $emit('update:include-agenda', value)"
        >
          {{ $t("activerecord.attributes.invitation_batch.include_agenda") }}
        </be-form-checkbox>

        <be-alert
          v-if="agendaAboveAttachmentLimit"
          variant="warning"
          class="mb-2"
        >
          {{
            $t(
              "components.meetings.invitations.above_attachment_limit_w_value_w_limit",
              {
                limit: meeting.attachment_limit.human,
                value: meeting.attachment_file_size.human,
              }
            )
          }}
        </be-alert>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col">
        <h4
          class="mb-0"
          v-text="$t('components.meetings.invitations.send_to')"
        />

        <div class="d-flex flex-column">
          <be-table
            :items="invitations"
            :fields="fields"
            :per-page="30"
            @row-clicked="(item) => select(item)"
          >
            <template #avatar="{ item }">
              <user-avatar :user="getUser(item.user_id)" />
            </template>

            <template #name="{ item }">
              <div>
                {{ getUserName(item.user_id) }}
              </div>

              <membership-subtitle class="small" :user-id="item.user_id" />
            </template>

            <template #email="{ item }">
              <be-link :href="`mailto:${getUserEmail(item.user_id)}`">
                {{ getUserEmail(item.user_id) }}
              </be-link>
            </template>

            <template #created_at="{ item }">
              {{ dateIfPresent(!!item.deleted_at ? null : item.created_at) }}
            </template>

            <template #options="{ item }">
              <be-form-group
                :label="$t('components.companies.invitations.check_to_invite')"
                label-sr-only
                class="m-0"
              >
                <be-form-checkbox
                  :id="`checked-${item.user_id}`"
                  :model-value="localSelectedUserIds.includes(item.user_id)"
                  :name="`checked-${item.user_id}`"
                  @change="(value) => select(item, value)"
                />
              </be-form-group>
            </template>
          </be-table>

          <div class="mt-2 d-md-flex justify-content-start">
            <div v-if="$slots.buttons">
              <slot name="buttons"> </slot>
            </div>

            <div class="mr-md-2">
              <be-button
                v-if="nbrUncheckedInvitations > 0"
                variant="outline-secondary"
                :disabled="companyUsers.length == 0"
                @click="selectEveryone(true)"
              >
                {{ $t("buttons.toggle_all_selection.select_all") }}
              </be-button>

              <be-button
                v-else
                variant="outline-secondary"
                :disabled="companyUsers.length == 0"
                @click="selectEveryone(false)"
              >
                {{ $t("buttons.toggle_all_selection.deselect_all") }}
              </be-button>
            </div>

            <membership-invitations
              v-if="
                checkPolicy('create_membership') &&
                $platform.features.user_administration
              "
              :button-text="
                $t('components.companies.invitations.add_users_to_company')
              "
              button-variant="outline-secondary"
              @updated="$emit('memberships-updated')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MembershipInvitations from "@/components/companies/memberships/MembershipInvitations.vue";
import MembershipSubtitle from "@/components/shared/MembershipSubtitle.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    MembershipInvitations,
    MembershipSubtitle,
  },

  props: {
    invitations: {
      type: Array,
      required: true,
    },

    selectedUserIds: {
      type: Array,
      required: true,
    },

    includeAgenda: {
      type: Boolean,
      default: true,
    },

    meeting: {
      type: Object,
      required: false,
      default: null,
    },

    message: {
      type: String,
      required: true,
    },

    preinvitation: {
      type: Boolean,
      default: false,
    },

    invitationHeader: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: [
    "memberships-updated",
    "update:include-agenda",
    "update:message",
    "update:selected-user-ids",
  ],

  data() {
    return {
      localSelectedUserIds: this.cloneDeep(this.selectedUserIds),
    };
  },

  computed: {
    ...mapGetters({
      company: "company/getCompany",
      companyUsers: "company/users",
    }),

    nbrUncheckedInvitations() {
      return this.invitations.length - this.localSelectedUserIds.length;
    },

    fields() {
      return [
        { key: "options", label: "", class: "text-center col-shrink" },
        { key: "avatar", label: "", class: "col-shrink" },
        {
          key: "name",
          label: this.translateAttribute("user", "name"),
        },
        {
          key: "email",
          label: this.translateAttribute("user", "email"),
        },
        {
          key: "created_at",
          class: "col-shrink",

          label:
            this.invitationHeader ||
            this.translateAttribute("invitation", "created_at"),
        },
      ];
    },

    agendaAboveAttachmentLimit() {
      if (!this.meeting) return false;

      return (
        this.meeting.attachment_file_size.bytes >
        this.meeting.attachment_limit.bytes
      );
    },
  },

  watch: {
    selectedUserIds: {
      handler(value) {
        this.localSelectedUserIds = this.cloneDeep(value);
      },

      deep: true,
    },
  },

  methods: {
    select(invitation, value) {
      if (value == null || value == undefined) {
        value = !this.localSelectedUserIds.includes(invitation.user_id);
      }

      if (value) {
        this.localSelectedUserIds.push(invitation.user_id);
      } else {
        this.localSelectedUserIds = this.localSelectedUserIds.filter(
          (id) => id != invitation.user_id
        );
      }
      this.$emit("update:selected-user-ids", this.localSelectedUserIds);
    },

    selectEveryone(value = true) {
      if (value) {
        this.localSelectedUserIds = this.invitations.map(
          (invitation) => invitation.user_id
        );
      } else {
        this.localSelectedUserIds = [];
      }
      this.$emit("update:selected-user-ids", this.localSelectedUserIds);
    },

    dateIfPresent(date) {
      if (date) {
        return this.$d(new Date(date), { format: "mini" });
      }
      return "-";
    },
  },
};
</script>
